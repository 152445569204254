.body {
  gap: 1rem;
}

.modifiers {
  gap: 0.25rem;
}

.modifier-row {
  gap: 0.25rem;
}

.scaling-factor-input {
  width: 3rem;
  text-align: right;
}

.modifier-label {

}

.remove-modifier-button {
  margin-left: .25rem;
}

.modifier-header-row-label {
 &:last-of-type {
   // Visually align with the row below
   padding-right: .7rem;
 }
}

.modifier-header-row {
  padding-bottom: 0.5rem;
  font-weight: 500;
}
